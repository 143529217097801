export enum APP_ROUTES {
    ROOT = '',
    MAINTAIN_ITEMS = '/maintain-items',
    MAINTAIN_ITEMS_ITEM_BY_ID = '/maintain-items/:id/:tab',
    LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot-password',
    CREATE_PASSWORD = '/create-password',
    CONFIGURATION = '/configuration',
    USER_MANAGEMENT = '/user-management',
    USER_MANAGEMENT_SECTION = '/user-management/:tab',
    USER_MANAGEMENT_ADD_NEW_USER = '/user-management/add-new-user',
    USER_MANAGEMENT_VIEW_USER = '/user-management/employees/:id',
    USER_MANAGEMENT_ADD_NEW_CUSTOMER = '/user-management/add-new-customer',
    USER_MANAGEMENT_VIEW_CUSTOMER = '/user-management/bringing-customers/:id',
    REPORTING = '/reporting',
    SETTLEMENT = '/settlement-bc',
    SETTLEMENT_ITEMS_BY_ID = '/settlement-bc/:id',
    CHECKOUT = '/check-out',
    CHECKOUT_SECTION = '/check-out/:tab',
    SHIPPING = '/shipping',
    ADD_NEW_ITEM = '/maintain-items/add-new-item',
    ADD_NEW_ITEM_SECTION = '/maintain-items/add-new-item/:tab',
    CHANGE_PASSWORD = '/change-password',
}
