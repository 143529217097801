import {
    types,
    SnapshotOut,
    applySnapshot,
    getEnv,
    flow,
    getRoot,
    Instance,
    getSnapshot,
} from 'mobx-state-tree';

import { StoreModel } from '.';

export const SaleItem = types
    .model('SaleItem', {
        id: types.identifier,
        amount: types.number,
        priceGross: types.maybeNull(types.optional(types.string, '')),
        itemNumber: types.optional(types.string, ''),
        comission: types.maybeNull(types.optional(types.string, '')),
        isDummy: types.optional(types.boolean, false),
        category: types.maybeNull(types.optional(types.string, '')),
        name: types.maybeNull(types.optional(types.string, '')),
        status: types.maybeNull(types.optional(types.string, '')),
        imageId: types.maybeNull(types.optional(types.string, '')),
        attachmentId: types.maybeNull(types.optional(types.string, '')),
        stock: types.maybeNull(types.optional(types.number, 0)),
    })
    .actions((self) => {
        const store: StoreModel = getRoot(self);
        return {
            changeAmount(value: number): void {
                self.amount = value;
                store.saleStore.calculateItems();
            },
            changePrice(value: string): void {
                self.priceGross = value;
                store.saleStore.calculateItems();
            },
        };
    });

export type ISaleItemSnapshotOut = SnapshotOut<typeof SaleItem>;

export const SaleStore = types
    .model('Sale', {
        _saleOrders: types.optional(types.array(SaleItem), []),
        selectedOrder: types.maybeNull(types.reference(SaleItem)),
        sum: types.optional(types.string, '0'),
        total: types.optional(types.string, '0'),
        discountInPercent: types.optional(types.string, ''),
        discountInEuro: types.optional(types.string, ''),
        isSaleLoading: types.boolean,
        _vatGroups: types.maybeNull(
            types.array(
                types.model('VATGroups', {
                    vatPercent: types.number,
                    amount: types.number,
                })
            )
        ),
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        let lastRequestBody: string;
        return {
            calculateItems: flow(function* () {
                if (Array.isArray(self._saleOrders)) {
                    try {
                        const prices = self._saleOrders.map((order) => ({
                            itemId: order.id,
                            amount: order.amount,
                            price: order.priceGross,
                        }));
                        const requestBody = {
                            prices,
                            discountPercent: self.discountInPercent || '0',
                            discount: self.discountInEuro || '0',
                        };

                        if (lastRequestBody === JSON.stringify(requestBody)) {
                            return;
                        }
                        lastRequestBody = JSON.stringify(requestBody);
                        const data: {
                            sum: number;
                            total: number;
                            vatGroups: { vatPercent: number; amount: number }[];
                        } = yield httpClient.post('items/calculate', requestBody);

                        const { sum, total, vatGroups } = data;

                        applySnapshot(self, {
                            ...self,
                            selectedOrder: self.selectedOrder as null,
                            sum: String(sum),
                            total: String(total),
                            _vatGroups: vatGroups,
                        });
                    } catch {
                        self.sum = '0';
                        self.total = '0';
                    }
                } else {
                    self.sum = '0';
                    self.total = '0';
                }
            }),
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        let initialState: SaleSnapshotStoreModel;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetSaleStore(): void {
                applySnapshot(self, initialState);
            },
            addItem(item: ISaleItemSnapshotOut): void {
                const isItemAlreadyInBasket = self._saleOrders.some(
                    (order) => order.id === item.id
                );
                const selectedOrder = self.selectedOrder?.id || item.id;
                applySnapshot(self, {
                    ...self,
                    selectedOrder,
                    _saleOrders: isItemAlreadyInBasket
                        ? self._saleOrders.map((order) => {
                              if (order.id === item.id) {
                                  return {
                                      ...order,
                                      amount:
                                          order.amount + 1 <= (order.stock || 0)
                                              ? order.amount + 1
                                              : order.amount,
                                  };
                              }
                              return order;
                          })
                        : [...self._saleOrders, item],
                });
                self.calculateItems();
            },
            clearAll(): void {
                applySnapshot(self, {
                    ...self,
                    selectedOrder: null,
                    _saleOrders: [],
                    sum: '0',
                    total: '0',
                    _vatGroups: [],
                });
            },
            removeById(id: string): void {
                applySnapshot(self, {
                    ...self,
                    selectedOrder: null,
                    _saleOrders: self._saleOrders.filter((item) => item.id !== id),
                });
                self.calculateItems();
            },
            selectRow(id: string | null): void {
                applySnapshot(self, {
                    ...self,
                    selectedOrder: id,
                });
            },
            setResultBlockFieldValues(values: {
                discountInPercent?: string;
                discountInEuro?: string;
            }): void {
                if (typeof values.discountInPercent === 'string') {
                    self.discountInPercent = values.discountInPercent;
                }
                if (typeof values.discountInEuro === 'string') {
                    self.discountInEuro = values.discountInEuro;
                }
            },
            doSale: flow(function* () {
                const prices = self._saleOrders.map((order) => ({
                    amount: order.amount,
                    price: order.priceGross,
                    isDummy: order.isDummy,
                    itemId: order.id,
                }));
                const requestBody = {
                    prices,
                    discountPercent: self.discountInPercent || '0',
                    discount: self.discountInEuro || '0',
                };
                try {
                    self.isSaleLoading = true;
                    yield httpClient.post('items/sale', requestBody);
                    (self as SaleStoreModel).resetSaleStore();
                } finally {
                    self.isSaleLoading = false;
                }
            }),
        };
    })
    .views((self) => {
        return {
            get saleOrders() {
                return [...self._saleOrders.map((item) => ({ ...item }))];
            },
            get orderLength() {
                return self._saleOrders.length;
            },
            get vatGroups() {
                return Array.isArray(self._vatGroups) ? [...self._vatGroups] : [];
            },
        };
    });

export type SaleStoreModel = Instance<typeof SaleStore>;
export type SaleSnapshotStoreModel = SnapshotOut<typeof SaleStore>;
