import {
    types,
    flow,
    getEnv,
    applySnapshot,
    getSnapshot,
    SnapshotOut,
} from 'mobx-state-tree';
import { SortingRule } from 'react-table';
import qs from 'qs';

import { GetSettlementListQueryParams, SettlementListResponse } from 'src/shared/types';
import { HttpClient } from 'src/lib/http-client/http-client';

const SettlementListItem = types.model('SettlementListItem', {
    id: types.identifier,
    bringCustomerNumber: types.maybeNull(types.optional(types.string, '')),
    name: types.maybeNull(types.optional(types.string, '')),
    firstname: types.maybeNull(types.optional(types.string, '')),
    zipCode: types.maybeNull(types.optional(types.string, '')),
    city: types.maybeNull(types.optional(types.string, '')),
    salesQuote: types.maybeNull(types.optional(types.number, 0)),
});

export type SettlementListItemSnapshotOut = SnapshotOut<typeof SettlementListItem>;

export const SettlementListStore = types
    .model('SettlementListStore', {
        _settlementList: types.optional(types.array(SettlementListItem), []),
        page: types.optional(types.number, 0),
        rowsPerPage: types.optional(types.number, 0),
        count: types.number,
        sortField: types.maybeNull(types.string),
        sortDesc: types.maybeNull(types.boolean),
        searchValue: types.optional(types.string, ''),
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        let initialState: SettlementListSnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore(): void {
                applySnapshot(self, initialState);
            },
            setPage(page: number) {
                self.page = page;
            },
            setRowsPerPage(rowsPerPage: number) {
                self.rowsPerPage = rowsPerPage;
                self.page = 0;
            },
            setSorting(sortBy?: SortingRule<string>) {
                if (sortBy) {
                    self.sortField = sortBy.id;
                    self.sortDesc = typeof sortBy.desc === 'boolean' ? sortBy.desc : null;
                } else {
                    self.sortField = null;
                    self.sortDesc = null;
                }
            },
            setSearchValue(value: string): void {
                self.searchValue = value;
                self.page = 0;
            },
            fetchSettlementBCList: flow(function* (params) {
                const [searchValue, rowsPerPage, page, sortField, sortDesc] =
                    params.queryKey;

                const skip = (page + 1) * rowsPerPage - rowsPerPage;
                const take = rowsPerPage;
                const queryParams: GetSettlementListQueryParams = {
                    skip,
                    take,
                };

                if (sortField && typeof sortDesc === 'boolean') {
                    queryParams.sortField =
                        sortField[0].toUpperCase() + sortField.slice(1);
                    queryParams.isAscending = sortDesc;
                }

                if (searchValue) {
                    queryParams.searchTerm = searchValue;
                }

                try {
                    const data = yield (
                        httpClient as HttpClient
                    ).get<SettlementListResponse>(
                        `users/bring-customers/settlement?${qs.stringify(queryParams)}`
                    );
                    applySnapshot(self, {
                        ...self,
                        count: data.count,
                        _settlementList: data.result,
                    });
                } catch {
                    applySnapshot(self, { ...self, _settlementList: [], count: 0 });
                }
            }),
        };
    })
    .views((self) => {
        return {
            get settlementList() {
                return [...self._settlementList];
            },
        };
    });

export type SettlementListSnapshotOut = SnapshotOut<typeof SettlementListStore>;
